<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="List"
                :showBtn="true"
                :showAddNew="false"
                title="Create new challan"
                @onClickCloseButton="navigateToListPage"
            />

            <div class="row mt-2 gy-1">
                <div class="col-md-3">
                    <label class="form-label">Order Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.order_date"
                        readonly
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Order No</label>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                        <input
                            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                            tabindex="1"
                            type="text"
                            placeholder=""
                            v-model="formData.bill_number"
                            disabled="disabled"
                        >
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="form-label">Delivery Challan Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="formData.date"
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Delivery Challan No</label>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                        <div class="input-group input-group-merge invoice-edit-input-group">
                            <div class="input-group-text">
                              <span>{{ prefix }}-</span>
                            </div>
                            <input type="number" min="1" class="form-control invoice-edit-input" placeholder=""
                                   v-model="serial">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gy-1">
                <div class="col-md-3">
                    <label class="form-label">Party</label>
                    <v-select
                        placeholder="Select Party"
                        v-model="formData.contact_profile_id"
                        :options="contacts"
                        label="name"
                        :reduce="name => name.id"
                        disabled="disabled"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Project</label>
                    <v-select
                        placeholder="Select Project"
                        v-model="formData.project_id"
                        :options="projects"
                        label="name"
                        :reduce="name => name.id"
                        disabled="disabled"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Business Unit</label>
                    <v-select
                        placeholder="Select Business"
                        v-model="formData.business_id"
                        :options="business"
                        label="name"
                        :reduce="name => name.id"
                        disabled="disabled"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Cost Centre</label>
                    <v-select
                        placeholder="Select Cost Centre"
                        v-model="formData.cost_centre_id"
                        :options="costCentres"
                        label="name"
                        :reduce="name => name.id"
                        disabled="disabled"
                    />
                </div>
                <div class="col-md-4">
                    <label class="form-label">Ship from (Warehouse)</label>
                    <v-select
                        placeholder="Select Warehouse Location"
                        v-model="formData.location_id"
                        :options="locations"
                        label="text"
                        :reduce="text => text.id"
                        readonly
                    />
                </div>
                <div class="col-md-4">
                    <label class="form-label">Receivable Accounts</label>
                    <v-select
                        placeholder="Account Head"
                        v-model="formData.account_head_id"
                        :options="accountPayable"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-4">
                    <label class="form-label">Vat Accounts</label>
                    <v-select
                        placeholder="Vat Payable Account Head"
                        v-model="formData.vat_payable_account_head_id"
                        :options="vatPayable"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Order No/PI No</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add order number"
                        v-model="formData.order_number"
                        readonly
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Sales Person</label>
                    <v-select
                        placeholder="Select Sales Person"
                        v-model="formData.sales_person_id"
                        :options="contacts"
                        label="name"
                        :reduce="name => name.id"
                        disabled="disabled"
                    />
                </div>
                <div class="col-md-3">
                    <label class="form-label">Commission %</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="number"
                        placeholder="Please add commission %"
                        v-model="formData.commission_percent"
                        readonly
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">LC No</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add LC number"
                        v-model="formData.lc_number"
                        readonly
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Country Or Origin</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add country"
                        v-model="formData.country"
                        readonly
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Currency</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add currency"
                        v-model="formData.currency"
                        readonly
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Loading Port</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add loading port"
                        v-model="formData.loading_port"
                        readonly
                    >
                </div>
                <div class="col-md-3">
                    <label class="form-label">Delivery Port</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="text"
                        placeholder="Please add LC number"
                        v-model="formData.delivery_port"
                        readonly
                    >
                </div>
            </div>
        </div>

        <div class="px-2">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a @click="formData.has_item_detail = true" :class="{'active' : formData.has_item_detail}" class="nav-link disabled" data-bs-toggle="tab"  aria-controls="home" role="tab" aria-selected="true">Item Details</a>
                </li>
                <li class="nav-item">
                    <a @click="formData.has_item_detail = false" :class="{'active' : !formData.has_item_detail}" class="nav-link disabled" data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
                </li>
            </ul>
        </div>

        <div class="px-3" v-if="loader == false">
            <Invoice
                class="mt-2"
                v-for="(data, index) in accountsDetails"
                :key="index"
                :index="index"
                :data="data"
                :products="products"
                :accountHeads="accountHeads"
                :vatRate="vatRate"
                :isItem="isItem"
                :locationId="formData.location_id"
                :business="business"
                :projects="projects"
                :costCentres="costCentres"
                @onClose="removeBill"
            />
            <Invoice
                class="mt-2"
                v-for="(data, index) in itemsDetails"
                :key="index"
                :index="index"
                :data="data"
                :products="products"
                :accountHeads="accountHeads"
                :vatRate="vatRate"
                :isItem="isItem"
                :locationId="formData.location_id"
                :business="business"
                :projects="projects"
                :costCentres="costCentres"
                @onClose="removeBill"
            />
        </div>

        <div class="col-12 px-2">
            <div class="mb-1">
                <label class="form-label" for="description">Memo</label>
                <vField
                  as="textarea"
                  name="description"
                  v-model="formData.description"
                  class="form-control"
                />
            </div>
        </div>

        <div class="pb-5 px-2 mt-4">
            <div class="d-flex flex-wrap gap-1 gy-2">
                <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)" class="btn btn-primary">
                  Save
                </button>
                <button :disabled="productLoader" @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
            </div>
        </div>
         <GlobalLoader v-if="loader"/>
    </div>
</template>

<script setup>
import { ref, inject, onMounted, computed, watch } from 'vue'
import {vatRate} from '@/data/inventory.js'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import handleProjects from "@/services/modules/procurement/project";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import TitleButton from '@/components/atom/TitleButton'
import Invoice from '@/components/molecule/company/inventory/delivery-chalan/Invoice.vue'
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";

const router = useRouter()
const route = useRoute()
let prefix = ref('')
let serial = ref('')

const showError =  inject('showError');
const showSuccess =  inject('showSuccess');

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {fetchBusinessList} = handleCBusinesses()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles} = handleContact()
const { fetchProjects } = handleProjects()
const { fetchCostCentreList } = handleCostCentres()

const companyId = computed(() => {
    return route.params.companyId
})
let loader =ref(false)
let productLoader =ref(false)
let saveButtonLoader=ref(false)
let saveNewButtonLoader =ref(false)
let products = ref([])
let accountHeads = ref([])
let contacts = ref([])
let business = ref([])
let locations = ref([])
let accountPayable = ref([])
let vatPayable = ref([])
let fetchedFromAPI = ref(false)
let projects = ref([])
let costCentres = ref([])

onMounted(async () => {
    loader.value=true
    let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=delivery_challan`);
    prefix.value = voucher.prefix;
    serial.value = voucher.serial;
    const companyQuery = `?company_id=${companyId.value}`
    let projectQuery = companyQuery + '&with_donors=1';
    const productRes = fetchProductList(companyQuery)
    const accountHeadRes = fetchAccountHead(companyQuery)
    const businessRes = fetchBusinessList(companyQuery)
    const businessLocationRes = fetchBusinessLocationsList(companyQuery)
    const contactRes = fetchContactProfiles(companyQuery)
    const payableRes = rest.fetchAccountReceivable(companyQuery)
    const vatPayableRes = rest.fetchVatPayable(companyQuery)
    const fetchSingleSalesOrder = rest.fetchSalesOrderById(route.params.salesId, companyQuery)
    const projectRes = fetchProjects(projectQuery)
    const costCenterRes = fetchCostCentreList(companyQuery)

    Promise.all([
        productRes.then(res=> {
            if(res.data) products.value = res.data
        }),
        accountHeadRes.then(res=> {
            if(res.data) accountHeads.value = res.data
        }),
        businessRes.then(res=> {
            if(res.data) business.value = res.data
        }),
        businessLocationRes.then(res=> {
          if(res.data) locations.value = res.data
        }),
        contactRes.then(res=> {
            if(res.data) contacts.value = res.data
        }),
        projectRes.then(res=> {
          if(res.data) projects.value = res.data
        }),
        costCenterRes.then(res=> {
          if(res.data) costCentres.value = res.data
        }),
        payableRes.then(res=> {
            if(res.data) accountPayable.value = res.data
            if(res.data?.length) {
                formData.value.account_head_id = res.data[0].id
            }
        }),
        vatPayableRes.then(res=> {
          if(res.data) vatPayable.value = res.data
          if(res.data?.length) {
            formData.value.vat_payable_account_head_id = res.data[0].id
          }
        }),
        fetchSingleSalesOrder.then(res => {
            if(res.data) {
                fetchedFromAPI.value = true
                formData.value = res.data
                formData.value.order_date = res.data.date
                formData.value.date =  new Date().toISOString().split('T')[0]
                if(res.data.has_item_detail) {
                  formData.value.item_details = res.data.general.map(item => {
                      const newItem =  {
                          ...item,
                          delivery_qty: 0,
                          sales_order_general_id: Number(item.id)
                      }
                      delete newItem.id;
                      return newItem;
                  })
                  formData.value.has_item_detail = true
                } else {
                  formData.value.account_details = res.data.ledgers
                  formData.value.has_item_detail = false
                }
            }
        })
    ])
    .then(() => {loader.value=false})
    .catch((err)=>{loader.value=false})
//   getProduct()
})
let formData = ref({
    company_id: companyId,
    contact_profile_id: null,
    account_head_id: null,
    vat_payable_account_head_id: null,
    business_id: null,
    project_id: null,
    cost_centre_id: null,
    location_id: null,
    status: 'pending',
    bill_number: '',
    date: '',
    has_item_detail: true,
    description: '',
    country: '',
    currency: '',
    loading_port: '',
    delivery_port: '',
    account_details: [],
    item_details: [
        {
            product_id: null,
            quantity: 1,
            delivered_qty: 0,
            delivery_qty: 0,
            rate: '',
            discount_amount: 0,
            discount_percent: 0,
            vat: 15,
            vat_amount: 0,
            description: "",
            business_id: null,
            project_id: null,
            cost_centre_id: null
        }
    ],
})
const hasItemDetails = computed(() => {
    return formData.value.has_item_detail
})
const itemsDetails = computed(() => {
    return formData.value.item_details
})
const accountsDetails = computed(() => {
    return formData.value.account_details
})

const isItem = computed(() => {
    return formData.value.has_item_detail ? true : false
})
const selectionText = computed(() => {
    return formData.value.has_item_detail ? 'Item details' : 'Accounting Details'
})

watch(hasItemDetails, (newValue) => {
    if(!newValue) {
      if(fetchedFromAPI.value === false){
        delete formData.value.item_details
        formData.value.account_details = [{
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            discount_amount: 0,
            discount_percent: 0,
            vat: 15,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null
        }]
      } else {
          fetchedFromAPI.value = false
      }
    }
    if(newValue) {
      if(fetchedFromAPI.value === false) {
        delete formData.value.account_details
        formData.value.item_details = [{
            product_id: null,
            quantity: 1,
            rate: '',
            vat: 15,
            vat_amount: 0,
            discount_amount: 0,
            discount_percent: 0,
            description: ""
        }]
      } else {
          fetchedFromAPI.value = false
      }
    }
})

function navigateToListPage() {
    router.push({name: 'approved-sales-orders', params: route.params, query: route.query})
}

function removeBill(index) {
    if(hasItemDetails.value) {
        formData.value.item_details.splice(index, 1)
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.splice(index, 1)
    }
}

function addNewBill() {
    if(hasItemDetails.value) {
        formData.value.item_details.push({
            product_id: null,
            quantity: 1,
            rate: '',
            discount_amount: 0,
            discount_percent: 0,
            vat: 15,
            vat_amount: 0,
            business_id: null,
            project_id: null,
            description: ''
        })
    }
    if(!hasItemDetails.value) {
        formData.value.account_details.push({
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            vat: 15,
            vat_amount: 0,
            description: '',
            taxable_amount: null,
            tax_rate: null
        })
    }
}
function handleSubmit(redirect=false) {
    if (! formData.value.vat_payable_account_head_id){
      showError('Please select a vat payable account head');
      return;
    }
    if(redirect) {
        saveButtonLoader.value = true
    } else {
        saveNewButtonLoader.value = true
    }
    formData.value.bill_number = prefix.value+'-'+serial.value
    if(!formData.value.date) formData.value.date = new Date().toISOString().split('T')[0]
    let copyFormData = JSON.parse(JSON.stringify(formData.value))
    copyFormData.has_item_detail = formData.value.has_item_detail ? 1 : 0
    copyFormData.sales_order_master_id = formData.value.id
    delete copyFormData.id
    if(isItem.value) {
        delete copyFormData.account_details
        copyFormData.item_details = formData.value.item_details
    }else {
         delete copyFormData.item_details
        copyFormData.account_details = formData.value.account_details
    }
    rest.createDeliveryChallan(copyFormData)
    .then(res => {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
        productLoader.value = false
        if(res.status) {
            showSuccess(res.message)
            if(redirect) navigateToListPage()
            resetForm()
        }
        if(!res.status) {
            showError(res.message)
        }
    })
    .catch( () => {
        saveButtonLoader.value = false
        saveNewButtonLoader.value = false
    })
}

// have to refactor
function resetForm() {
    if(!isItem.value) {
        formData.value.account_details = [{
            account_head_id: null,
            vat_payable_account_head_id: null,
            amount: '',
            vat: 15,
            vat_amount: 0,
            description: '',
            discount_amount: 0,
            discount_percent: 0,
            taxable_amount: null,
            business_id: null,
            project_id: null,
            tax_rate: null
        }]
    }
    if(isItem.value) {
        formData.value.item_details = [{
            product_id: null,
            quantity: 1,
            rate: '',
            vat: 15,
            vat_amount: 0,
            discount_amount: 0,
            discount_percent: 0,
            business_id: null,
            project_id: null,
            description: ""
        }]
    }
}

</script>
